import { send } from "../index";

export function getCouponPolicyList(data, opts = {}) {
	//  政策列表
  return send({
    url: "/admin/couponPolicy/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveCouponPolicy(data, opts = {}) {
	//  保存政策
  return send({
    url: "/admin/couponPolicy/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateCouponPolicy(data, opts = {}) {
	//  修改政策
  return send({
    url: "/admin/couponPolicy/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCouponPolicyDetail(data, opts = {}) {
	//  政策详情
  return send({
    url: "/admin/couponPolicy/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteCouponPolicy(data, opts = {}) {
	//  删除政策
  return send({
    url: "/admin/couponPolicy/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function useBatchNoList(data, opts = {}) {
	//  使用政策适用批次号列表
  return send({
    url: "/admin/couponPolicy/useBatchNoList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function replaceCouponPolicy(data, opts = {}) {
	//  更换使用政策
  return send({
    url: "/admin/couponPolicy/replaceCouponPolicy.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function batchReplaceCouponPolicy(data, opts = {}) {
	//  批量使用政策
  return send({
    url: "/admin/couponPolicy/batchReplaceCouponPolicy.do",
    method: "POST",
    data,
    ...opts,
  });
}